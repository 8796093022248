import React from 'react';

import { Box } from '@mui/material';

const MainButton = ( props ) =>
{
  const { iconResting, iconActive, isOpen, size, backgroundColor, onClick, ...rest } = props;

    return (
        <Box
            component="li"
            onClick={onClick}
            sx={ {
                display: 'flex',
                border: 'none',
                borderRadius: '50%',
                boxShadow: '0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)',
                cursor: 'pointer',
                outline: 'none',
                padding: '0',
                WebkitUserDrag: 'none',
                fontWeight: 'bold',
                justifyContent: 'center',
                alignItems: 'center',
                width: size,
                height: size,
                backgroundColor: backgroundColor,
            } }
        >
            <Box
                component="div"
                sx={ {
                    display: 'flex',
                    textDecoration: 'none',
                    WebkitTransition: '-webkit-transform 300ms',
                    transition: 'transform 300ms',
                    WebkitTransform: `rotate(${isOpen ? 180 : 0}deg)`,
                    transform: `rotate(${isOpen ? 180 : 0}deg)`
                } }
                {...rest}
            >
                {isOpen ? iconActive : iconResting}
            </Box>
        </Box>
    );
};

export default MainButton;