import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';

import { Grid, Alert as MuiAlert, Snackbar, Stack, TextField } from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import SendIcon from '@mui/icons-material/Send';

import MaskInput from '../../components/MaskInput';

const Alert = React.forwardRef(function Alert(props, ref)
{
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Contact = () =>
{
    const formRef = useRef();

    const [form, setForm] = useState({
        user_name: '',
        user_phone: '',
        user_company: '',
        user_email: '',
        message: ''
    });

    const eraseFields = () =>
    {
        setForm({
            user_name: '',
            user_phone: '',
            user_company: '',
            user_email: '',
            message: ''
        });
    };

    const [openSuccess, setOpenSuccess] = useState(false);
    
    const [openError, setOpenError] = useState(false);

    const handleClickSuccess = () => {
        setOpenSuccess(true);
    };

    const handleClickError = () => {
        setOpenError(true);
    };

    const handleCloseSuccess = (event, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setOpenSuccess(false);
    };

    const handleCloseError = (event, reason) =>
    {
        if (reason === 'clickaway')
        {
            return;
        }

        setOpenError(false);
    };

    const [loading, setLoading] = useState(false);

    const handleLoading = (status) =>
    {
        setLoading(status);
    };

    const sendEmail = (e) =>
    {
        handleLoading(true);

        e.preventDefault();

        emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formRef.current, process.env.REACT_APP_USER_ID)
        .then((response) =>
        {
            console.log('SUCCESS!', response.status, response.text);
            handleClickSuccess();
            eraseFields();

        }).catch((error) =>
        {
            console.log('FAILED...', error.text);
            handleClickError();

        }).finally(() => 
        {
            handleLoading(false);

        });
        
        
    };

    const SubmitButton = () =>
    {
        if ((form.user_name !== '') && (form.user_phone !== '') && (form.user_phone.replaceAll(/\D/g,"").length >= 10) && (form.user_company !== '') && (form.user_email !== '') && (form.user_email.includes("@")) && (form.message !== ''))
        {
            return (
                <LoadingButton
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    type="submit"
                    variant="contained"
                >
                    Enviar
                </LoadingButton>
            )
        } else
        {
            return (
                <LoadingButton
                    endIcon={<SendIcon />}
                    loading={loading}
                    loadingPosition="end"
                    type="submit"
                    variant="contained"
                    disabled
                >
                    Enviar
                </LoadingButton>
            )
        };
    };

    return (
        <>
            <form ref={formRef} onSubmit={sendEmail}>
                <Grid 
                    container
                    rowSpacing={1}
                    columnSpacing={1}
                    id="contato"
                    sx={ { 
                        mt: 0,
                        ml: 0,
                        p: 2,
                        border: 1,
                        borderColor: 'lightgrey',
                        borderRadius: 2,
                        boxShadow: '5px 5px 10px darkgrey'
                    } }
                >
                    <Grid item xs={12} sm={6} lg={12} xl={6} paddingLeft={'0px !important'} paddingRight={ [ '0px !important', '5px !important', '5px !important', '0px !important' ] }>
                        <TextField 
                            required
                            fullWidth
                            id="nome"
                            label="Nome"
                            variant="outlined"
                            name="user_name"

                            value={ form.user_name }
                            
                            onChange={ (event) =>
                                {
                                    setForm({ ...form, user_name: event.target.value })
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={12} xl={6} paddingLeft={ [ '0px !important', '5px !important', '5px !important', '0px !important' ] }>
                        <MaskInput
                            mask={form.user_phone.replaceAll(/\D/g,"").length < 11 ? "(99) 9999-99999" : "(99) 9 9999-9999"}
                            id="numeroTel"
                            label="Número"
                            name="user_phone"

                            value={ form.user_phone }
                            
                            onChange={ (event) =>
                                {
                                    setForm({ ...form, user_phone: event.target.value })
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={12} xl={6} paddingLeft={'0px !important'} paddingRight={ [ '0px !important', '5px !important', '5px !important', '0px !important' ] }>
                        <TextField
                            fullWidth
                            id="empresa"
                            label="Empresa"
                            variant="outlined"
                            name="user_company"

                            value={ form.user_company }
                            
                            onChange={ (event) =>
                                {
                                    setForm({ ...form, user_company: event.target.value })
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={12} xl={6} paddingLeft={ [ '0px !important', '5px !important', '5px !important', '0px !important' ] }>
                        <TextField 
                            required
                            fullWidth
                            type="email"
                            id="email"
                            label="E-mail"
                            variant="outlined"
                            name="user_email"

                            value={ form.user_email }
                            
                            onChange={ (event) =>
                                {
                                    setForm({ ...form, user_email: event.target.value })
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} paddingLeft={'0px !important'}>
                        <TextField 
                            required
                            fullWidth
                            id="mensagem"
                            label="Mensagem"
                            variant="outlined"
                            name="message"
                            multiline
                            rows={5}

                            value={ form.message }
                            
                            onChange={ (event) =>
                                {
                                    setForm({ ...form, message: event.target.value })
                                }
                            }
                        />
                    </Grid>

                    <Grid item xs={12} textAlign="end">
                        <SubmitButton />
                    </Grid>
                </Grid>
            </form>

            <Stack spacing={2} sx={ { width: '100%' } } >
                <Snackbar open={openSuccess} autoHideDuration={4000} onClose={handleCloseSuccess}>
                    <Alert onClose={handleCloseSuccess} severity="success" sx={ { width: '100%' } } >
                        Sua mensagem foi enviada com sucesso!
                    </Alert>
                </Snackbar>

                <Snackbar open={openError} autoHideDuration={8000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="error" sx={ { width: '100%' } } >
                        Error! Estamos com algum problema em nosso sistema, tente novamente mais tarde ou entre em contato por outro canal.
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}

export default Contact;