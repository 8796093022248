import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { Box, Button, Grid, Typography } from '@mui/material';

import mainLogo from '../../../media/Logo/webp/logo-xl.webp';

import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const WidgetFooter = () =>
{
    return (
        <>
            <Box
                component='footer'
                sx={ {
                    bgcolor: '#121212',
                    p: 2,
                    borderTop: 1,
                    borderColor: 'lightgray',
                } }
            >
                <Grid container>
                    <Grid item xs={12} md={6} width="100%">
                        <Box
                            component="div"
                            sx={ { 
                                position: "relative",
                                minWidth: "100%",
                                minHeight: [70]
                            } }
                        >
                            <Box 
                                component="img"
                                alt="main-logo"
                                src={mainLogo}
                                sx={ { 
                                    position: "absolute", 
                                    width: ["80%", "65%", "65%"],
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    textAlign: "center"
                                } }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid
                                item
                                xs={12}
                                sx={ { 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: ["center", "center", "start"]
                                } }
                            >
                                <MailOutlineIcon sx={ { color: "#C9935E" } } />
                                <Typography 
                                    variant="h5"
                                    color="white"
                                    sx={ {
                                        display: "inline-block",
                                        color: "#818081",
                                        fontWeight: 600,
                                        ml: 1
                                    } }
                                >
                                    E-mail
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={ { 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: ["center", "center", "start"]
                                } }
                            >
                                <Button
                                    type="submit"
                                    variant="string"
                                    sx={ {
                                        color: "white",
                                        bgcolor: "transparent",
                                        border: 0,
                                        textTransform: 'none'
                                    } }

                                    href="mailto:contato@phep.eng.br"
                                >
                                    contato@phep.eng.br
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={ { 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: ["center", "center", "start"]
                                } }
                            >
                                <PhoneIcon sx={ { color: "#C9935E" } } />
                                <Typography 
                                    variant="h5"
                                    color="white"
                                    sx={ {
                                        display: "inline-block",
                                        color: "#818081",
                                        fontWeight: 600,
                                        ml: 1
                                    } }
                                >
                                    Telefone
                                </Typography>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={ { 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: ["center", "center", "start"]
                                } }
                            >
                                <Button
                                    type="submit"
                                    variant="string"
                                    sx={ {
                                        color: "white",
                                        bgcolor: "transparent",
                                        border: 0,
                                        textTransform: 'none'
                                    } }

                                    href="tel:+551126292693"
                                >
                                    (11) 2629-2693 Ramal 213
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={ { 
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: ["center", "center", "start"]
                                } }
                            >
                                <Link
                                    to={ "#faleconosco" }
                                    style={ { textDecoration: 'none', color: 'white' } }
                                    smooth
                                >
                                    <Button 
                                        type="button"
                                        variant="contained"
                                        sx={ { 
                                            bgcolor: 'rgba(81,80,81,.1)', 
                                            border: 1,
                                            '&:hover': {
                                                bgcolor: 'rgba(81,80,81,1)',
                                                color: '#AAA'
                                            }
                                        } }
                                    >
                                        Fale conosco
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default WidgetFooter;