import React, { useState } from 'react';

import { Box } from '@mui/material';

import FloatinMenu from './FloatingMenu';
import MainButton from './MainButton';
import ChildButton from './ChildButton';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MessageIcon from '@mui/icons-material/Message';

const FloatingActions = () =>
{
    const [ isOpen, setIsOpen ] = useState();

    const handleOpen = () =>
    {
        setIsOpen(!isOpen);
    };

    return (
        <Box 
            componente="div"
            sx={ { 
                position: 'fixed',
                right: '40px',
                bottom: '40px',
                zIndex: '100'
            } }
        >
            <FloatinMenu
                slideSpeed={500}
                direction="up"
                spacing={1}
                isOpen={isOpen}
            >
                <MainButton
                    iconResting={<MenuIcon style={ { fontSize: 20, color: "white" } } />}
                    iconActive={<MenuOpenIcon style={ { fontSize: 20, color: "white" } } />}
                    backgroundColor="gray"
                    isOpen={isOpen}
                    onClick={handleOpen}
                    size={56}
                />

                <ChildButton
                    icon={<MessageIcon style={{ fontSize: 20, color: 'white' }}  />}
                    backgroundColor="dodgerblue"
                    size={40}
                    link="#faleconosco"
                />

                <ChildButton
                    icon={<LocalPhoneIcon style={{ fontSize: 20, color: 'white' }}  />}
                    backgroundColor="mediumseagreen"
                    size={40}
                    onClick={() => window.location.href = "tel:+551126292693"}
                />

                <ChildButton
                    icon={<MailIcon style={{ fontSize: 20, color: '#C9935E' }} />}
                    backgroundColor="#444444"
                    size={40}
                    onClick={() => window.location.href = "mailto:contato@phep.eng.br"}
                />

                <ChildButton
                    icon={<ArrowUpwardIcon style={{ fontSize: 20 }} color="Black" />}
                    backgroundColor="gray"
                    size={40}
                    link="#home"
                />

            </FloatinMenu>
        </Box>
    );
}

export default FloatingActions;