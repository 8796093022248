import React  from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import Logo from '../../media/Logo/webp/logo-min.webp';
import LogoMin from '../../media/Logo/webp/logo-min-reduced.webp';

import { AppBar, Box, Toolbar, Typography, IconButton, Grid } from '@mui/material';

import { Home as HomeIcon, Info as InfoIcon, FactCheck as FactCheckIcon, Email as EmailIcon } from '@mui/icons-material';

import { ThemeProvider } from '@mui/material/styles';

const Navbar = (props) =>
{
    const { theme } = props;

    return (
        <ThemeProvider theme={ theme }>
            <AppBar position="fixed" id="navbar">
                <Toolbar>
                    <Grid variant="outlined" component="div" sx={{ flexGrow: 1 }}>
                        <Box
                            component="img"
                            sx={{
                                width: '100%',
                                height: '100%',
                                content: {
                                    xs: `url(${LogoMin})`,
                                    md: `url(${Logo})`,
                                }
                            }}
                            alt="Logo"
                        />
                    </Grid>
                    <Link
                        // to={ "/home" }
                        to={ "#home" }
                        style={ { textDecoration: 'none', color: 'white' } }
                        smooth
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mx: [0.2, 0.5] }}
                        >
                            <Grid container direction="column">
                                <Grid item xs={12} sx={{ display:'inline-flex', justifyContent: 'center' }}>
                                    <HomeIcon/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle2'
                                        sx={ { display: ['none', 'inherit'] } }
                                    >
                                        Home
                                    </Typography>
                                </Grid>
                            </Grid>
                        </IconButton>
                    </Link>

                    <Link
                        // to={ "/sobre" }
                        to={ "#sobre" }
                        style={ { textDecoration: 'none', color: 'white' } }
                        smooth
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mx: [0.2, 0.5] }}
                        >
                            <Grid container direction="column">
                                <Grid item xs={12} sx={{ display:'inline-flex', justifyContent: 'center' }}>
                                    <InfoIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle2'
                                        sx={ { display: ['none', 'inherit'] } }
                                    >
                                        Sobre nós
                                    </Typography>
                                </Grid>
                            </Grid>
                        </IconButton>
                    </Link>

                    <Link
                        // to={ "/servicos" }
                        to={ "#servicos" }
                        style={ { textDecoration: 'none', color: 'white' } }
                        smooth
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mx: [0.2, 0.5] }}
                        >
                            <Grid container direction="column">
                                <Grid item xs={12} sx={{ display:'inline-flex', justifyContent: 'center' }}>
                                    <FactCheckIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle2'
                                        sx={ { display: ['none', 'inherit'] } }
                                    >
                                        Serviços
                                    </Typography>
                                </Grid>
                            </Grid>
                        </IconButton>
                    </Link>

                    <Link
                        // to={ "/contato" }
                        to={ "#faleconosco" }
                        style={ { textDecoration: 'none', color: 'white' } }
                        smooth
                    >
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mx: [0.2, 0.5] }}
                        >
                            <Grid container direction="column">
                                <Grid item xs={12} sx={{ display:'inline-flex', justifyContent: 'center' }}>
                                    <EmailIcon />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        variant='subtitle2'
                                        sx={ { display: ['none', 'inherit'] } }
                                    >
                                        Contato
                                    </Typography>
                                </Grid>
                            </Grid>
                        </IconButton>
                    </Link>

                    {/* <Button color="inherit">Login</Button> */}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default Navbar;