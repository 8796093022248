import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import { Box } from '@mui/material';

import { Directions } from '../FloatingMenu';

const Wrapper = (props) =>
{
    const { isOpen, slideSpeed, background, size, spacing, direction, ...rest } = props;

    return (
        <Box
            component="div"
            sx={{
                background,
                display: 'flex',
                border: 'none',
                borderRadius: '50%',
                boxShadow: '0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28)',
                cursor: 'pointer',
                outline: 'none',
                padding: '0',
                WebkitUserDrag: 'none',
                fontWeight: 'bold',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: isOpen ? 1 : 0,
                transition: `all ${slideSpeed}ms`,
                width: size,
                height: size,
                marginTop: direction === 'down' ? spacing : 0,
                marginBottom: direction === 'up' ? spacing : 0,
                marginLeft: direction === 'right' ? spacing : 0,
                marginRight: direction === 'left' ? spacing : 0,
                pointerEvents: isOpen ? 'auto' : 'none'
            }}
            {...rest}
        />
    );
};

const ChildButton = ( props ) =>
{
    const { icon, link, direction = Directions.Up, index = 1, size = 40,
        spacing = 0, isOpen = false, onClick = null, ...rest } = props;

    const offsetX =
        direction === 'right' ? (size + spacing) * index : direction === 'left' ? (size + spacing) * index * -1 : 0;

    const offsetY = direction === 'down' ? (size + spacing) * index : (direction === 'up' ? (size + spacing) * index * -1 : 0);

    return (
        link ? 
        <li>
            <Link
                to={ link }
                style={ { textDecoration: 'none', color: 'white' } }
                aria-label={link}
                smooth
            >
                <Wrapper
                    isOpen={isOpen}
                    size={size}
                    spacing={spacing}
                    direction={direction}
                    {...rest}
                    onClick={isOpen ? onClick : null}
                    style={{
                        transform: `translate(${isOpen ? 0 : -offsetX}px, ${
                        isOpen ? 0 : -offsetY
                        }px)`
                    }}
                >
                    {icon}
                </Wrapper>
            </Link>
        </li>

        :
        
        <li>
            <Wrapper
                isOpen={isOpen}
                size={size}
                spacing={spacing}
                direction={direction}
                {...rest}
                onClick={isOpen ? onClick : null}
                style={{
                    transform: `translate(${isOpen ? 0 : -offsetX}px, ${
                    isOpen ? 0 : -offsetY
                    }px)`
                }}
            >
                {icon}
            </Wrapper>
        </li>
    );
};

export default ChildButton