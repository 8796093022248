import React from 'react';

import { Grid } from '@mui/material';

import Titulo from '../../components/Typography/Titulo';
import CardText from '../../components/Card/CardText';

import { AssistantPhoto as AssistantPhotoIcon, Visibility as VisibilityIcon, GpsFixed as GpsFixedIcon } from '@mui/icons-material';

const GpsFixedIconStyled = <GpsFixedIcon sx={ { color: "rgba(50, 50, 50, 1)" } } />
const VisibilityIconStyled = <VisibilityIcon sx={ { color: "rgba(50, 50, 50, 1)" } } />
const AssistantPhotoIconStyled = <AssistantPhotoIcon sx={ { color: "rgba(50, 50, 50, 1)" } } />

const About = () =>
{
    const itens = [
        { id: 1, title: 'Missão', description: 'Executar serviços e projetos de engenharia do mais alto nível técnico, ético e profissional.', icon: GpsFixedIconStyled},
        { id: 2, title: 'Visão', description: 'Se destacar no mercado nacional conquistando seus clientes e parceiros.', icon: VisibilityIconStyled},
        { id: 3, title: 'Valores', description: 'Ter confiança nas pessoas e nas relações; ter respeito e ética; atingir seus resultados e de seus clientes; ser eficiente e eficaz em seus objetivos.', icon: AssistantPhotoIconStyled}
    ];

    const createCard = itens.map((item) =>
        <CardText key={item.id} titulo={item.title} descricao={item.description} icon={item.icon}/>
    );

    return (
        <Grid container minWidth="100%" sx={ { px: 3 } }>
            <Grid item width={'100%'} xs={12}>
                <Titulo text="Sobre nós"/>
            </Grid>

            <Grid item xs={12} sx={ { mb: 2 } }>
                <Grid container textAlign="center" alignItems="stretch" justifyContent="center">
                    {createCard}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default About;