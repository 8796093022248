import React from 'react';

import { Box } from '@mui/material';

import CardImg from '../../components/Card/CardImg';

import Titulo from '../../components/Typography/Titulo';

import Images from '../../media/PHServices/webp';

const PHServices = () =>
{
    const itens = [
        {
            id: 1,
            title: 'Engenharia multidisciplinar',
            description: 'Projetos multidisciplinares de engenharia para indústrias e comércios, atendendo demandas nas mais diversas áreas, como por exemplo: civil; arquitetura; estrutura metálica; elétrica; instrumentação; automação; mecânica; tubulação; heating, ventilation and air conditioner - HVAC; processos; utilidades; hidráulica e corpo de bombeiros.',
            photoxs: Images.EMXS,
            photosm: Images.EMSM,
            photomd: Images.EMMD,
            photolg: Images.EMLG,
        },
        {
            id: 2,
            title: 'Análise e viabilidade de projetos',
            description: 'Desenvolver estudos de viabilidade em conjunto com o cliente, analisando todas as variáveis necessárias para uma boa decisão. Avaliar o retorno econômico do projeto, o valor, prazo de implantação e sua aprovação junto a órgãos governamentais.',
            photoxs: Images.AVXS,
            photosm: Images.AVSM,
            photomd: Images.AVMD,
            photolg: Images.AVLG,
        },
        {
            id: 3,
            title: 'Due diligence',
            description: 'Desenhar e desenvolver junto com o cliente processos de Due Diligence para venda ou aquisição de ativos e/ou negócios. Analisar e desenvolver cenários para a venda ou aquisição de negócios, com intuito de dirimir dúvidas e pautar a melhor decisão.',
            photoxs: Images.DDXS,
            photosm: Images.DDSM,
            photomd: Images.DDMD,
            photolg: Images.DDLG,
            hideImgText: true
        },
        {
            id: 4,
            title: 'Auxílio em licenciamentos',
            description: 'Para êxito de um projeto, a obtenção das licenças governamentais são extremamente necessárias. Desta forma, a PH Engenharia e Projetos desenvolve e auxilia o cliente na obtenção de licenciamentos para atividades industriais e comerciais.',
            photoxs: Images.ALXS,
            photosm: Images.ALSM,
            photomd: Images.ALMD,
            photolg: Images.ALLG,
        },
        {
            id: 5,
            title: 'Orçamentos',
            description: 'Desenvolver e ajudar o cliente a elaborar orçamentos de capex e opex para o seu projeto. Os orçamentos em suas diversas fases, desde a fase de estudo de viabilidade até o orçamento final de execução, são de extrema importância para a tomada de decisão.',
            photoxs: Images.ORCXS,
            photosm: Images.ORCSM,
            photomd: Images.ORCMD,
            photolg: Images.ORCLG,
        },
        {
            id: 6,
            title: 'Gestão de obras e projetos',
            description: 'Para o sucesso de um projeto, o correto gerenciamento deve ser feito administrando equipes, prazos, escopos, qualidade e custos. A PH Engenharia e Projetos tem a expertise necessária para fazer um bom gerenciamento de projetos.',
            photoxs: Images.GOPXS,
            photosm: Images.GOPSM,
            photomd: Images.GOPMD,
            photolg: Images.GOPLG,
        },
        {
            id: 7,
            title: 'Capex e Opex',
            description: 'Tão importante quanto ter um bom orçamento de execução de um projeto, é ter um bom orçamento de operação. A PH Engenharia e Projetos tem o conhecimento necessário para que junto com a sua empresa, elabore e dimensione estes custos.',
            photoxs: Images.COXS,
            photosm: Images.COSM,
            photomd: Images.COMD,
            photolg: Images.COLG,
        },
        {
            id: 8,
            title: 'Avaliações de ativos',
            description: 'Antes de se adquirir um ativo, é necessário uma criteriosa avaliação de seu passado, presente e futuro, bem como seu correto valor de mercado e/ou de construção. A PH Engenharia tem vasta experiência nesta área e poderá ajudar seus clientes na melhor escolha.',
            photoxs: Images.AAXS,
            photosm: Images.AASM,
            photomd: Images.AAMD,
            photolg: Images.AALG,
        },
        {
            id: 9,
            title: 'Assistência em perícia judicial',
            description: 'Eventualmente qualquer empresa pode se envolver em disputas judiciais das mais variadas origens, e em alguns casos, haverá a necessidade de uma perícia judicial técnica de engenharia. A PH Engenharia e Projetos tem a expertise necessária para oferecer esse serviço, auxiliando tecnicamente departamento jurídico de seus clientes.',
            photoxs: Images.APJXS,
            photosm: Images.APJSM,
            photomd: Images.APJMD,
            photolg: Images.APJLG,
        },
    ];

    const createCard = itens.map((item) =>
        <CardImg key={item.id} titulo={item.title} descricao={item.description} foto={ { xs: item.photoxs, sm: item.photosm, md: item.photomd, lg: item.photolg } } imgLeft={(item.id % 2 === 0) ? false : true } hideImgText={item.hideImgText === true ? true : false} />
    );

    return (
        <Box
            component="div"
            sx={ { px: 3 } }
        >
            <Titulo text="Nossos serviços" id="servicos"/>
            
            {createCard}

        </ Box>
    );
}

export default PHServices;