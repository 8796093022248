import React from 'react';
// import { Route, Routes } from 'react-router-dom';

import { Box, CssBaseline, Container, Divider } from '@mui/material';

import { createTheme } from '@mui/material/styles';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FloatingActions from './components/FloatingActions';

import Home from './views/Home';
import About from './views/About';
import Services from './views/PHServices';
import Contact from './views/Contact';

const App = () =>
{
    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        },
    });

    return (
        <Box
            component="div"
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            } }
        >
            <CssBaseline />
            <Navbar theme={ darkTheme }/>
            <Container component='main' maxWidth={false} sx={ { px: '0px !important' } }>
                <FloatingActions />

                {/* Website com páginas diferentes: rotas para acessar as diversas páginas */}
                {/* <Routes>
                    <Route path={ '/' } element={ <Home /> }/>
                    <Route path={ '/home' } element={ <Home /> }/>
                    <Route path={ '/index' } element={ <Home /> }/>

                    
                    <Route path={ '/sobre' } element={ <About /> }/>
                    
                    <Route path={ '/servicos' } element={ <Services /> }/>

                    <Route path={ '/contato' } element={ <Contact /> }/>
                </Routes> */}

                {/* Website com apenas uma página */}

                <Box
                    component="div"
                    id="home"
                    sx={ {
                        display: 'block',
                        position: 'relative',
                        top: ['-57px', '-70px'],
                        visibility: 'hidden',
                    } }
                />

                <Home />
                
                <Divider variant="middle" sx={ { mx: 0, width: '100%' } }/>

                <Box
                    component="div"
                    id="sobre"
                    sx={ {
                        display: 'block',
                        position: 'relative',
                        top: ['-57px', '-70px'],
                        visibility: 'hidden',
                    } }
                />

                <About />
                
                <Divider variant="middle" sx={ { mx: 0, width: '100%' } }/>

                <Box
                    component="div"
                    id="servicos"
                    sx={ {
                        display: 'block',
                        position: 'relative',
                        top: ['-57px', '-70px'],
                        visibility: 'hidden',
                    } }
                />
                
                <Services />
                
                <Divider variant="middle" sx={ { mx: 0, width: '100%' } }/>

                <Box
                    component="div"
                    id="contato"
                    sx={ {
                        display: 'block',
                        position: 'relative',
                        top: ['-57px', '-70px'],
                        visibility: 'hidden',
                    } }
                />
                
                <Contact />

            </Container>
            <Footer />
        </Box>
    );
}

export default App;