import React from 'react';
import InputMask from 'react-input-mask';

import { TextField } from '@mui/material';

const MaskInput = (props) =>
{
    const { mask, value, onChange, label, name } = props;

    return (
        <InputMask
            mask={ mask }
            value={ value }
            onChange={ onChange }>
            { (inputProps) => <TextField
                { ...inputProps }
                required
                margin="dense"
                id="name"
                name={ name }
                label={ label }
                type="text"
                fullWidth
                variant="outlined"

                sx={ { m: 0 }
                }
            /> }
        </InputMask >
    );
};

export default MaskInput;