import React from 'react';

import { Box, Grid } from '@mui/material';

import Titulo from '../../components/Typography/Titulo';

import Contact from '../../components/Contact';
import Map from '../../components/Map';

const ContactUs = () =>
{

    return (
        <Box
            component="div"
            sx={ { px: 3 } }
        >
            <Grid container columnSpacing={5}>
                <Grid item lg={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                component="div"
                                id="faleconosco"
                                sx={ {
                                    display: 'block',
                                    position: 'relative',
                                    top: ['-57px', '-70px'],
                                    visibility: 'hidden',
                                } }
                            />
                            <Titulo text='Fale conosco'/>
                        </Grid>
                        <Grid item xs={12}>
                        <Contact />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item width="100%" lg={6}>
                    <Grid container minWidth="100%">
                        <Grid item width={'100%'} xs={12}>
                            <Titulo text='Nosso escritório' />
                        </Grid>
                        <Map />
                    </Grid>
                </Grid>
            </Grid>
        </ Box>
    );
}

export default ContactUs;