import AAXS from './xs/aa-xs.webp';
import ALXS from './xs/al-xs.webp';
import APJXS from './xs/apj-xs.webp';
import AVXS from './xs/av-xs.webp';
import COXS from './xs/co-xs.webp';
import DDXS from './xs/dd-xs.webp';
import EMXS from './xs/em-xs.webp';
import GOPXS from './xs/gop-xs.webp';
import ORCXS from './xs/orc-xs.webp';

// import AASM from './sm/aa-sm.webp';
// import ALSM from './sm/al-sm.webp';
// import APJSM from './sm/apj-sm.webp';
// import AVSM from './sm/av-sm.webp';
// import COSM from './sm/co-sm.webp';
// import DDSM from './sm/dd-sm.webp';
// import EMSM from './sm/em-sm.webp';
// import GOPSM from './sm/gop-sm.webp';
// import ORCSM from './sm/orc-sm.webp';

// import AAMD from './md/aa-md.webp';
// import ALMD from './md/al-md.webp';
// import APJMD from './md/apj-md.webp';
// import AVMD from './md/av-md.webp';
// import COMD from './md/co-md.webp';
// import DDMD from './md/dd-md.webp';
// import EMMD from './md/em-md.webp';
// import GOPMD from './md/gop-md.webp';
// import ORCMD from './md/orc-md.webp';

// import AALG from './lg/aa-lg.webp';
// import ALLG from './lg/al-lg.webp';
// import APJLG from './lg/apj-lg.webp';
// import AVLG from './lg/av-lg.webp';
// import COLG from './lg/co-lg.webp';
// import DDLG from './lg/dd-lg.webp';
// import EMLG from './lg/em-lg.webp';
// import GOPLG from './lg/gop-lg.webp';
// import ORCLG from './lg/orc-lg.webp';


const Images = {
    AAXS, ALXS, APJXS, AVXS, COXS, DDXS, EMXS, GOPXS, ORCXS,
    // AASM, ALSM, APJSM, AVSM, COSM, DDSM, EMSM, GOPSM, ORCSM,
    // AAMD, ALMD, APJMD, AVMD, COMD, DDMD, EMMD, GOPMD, ORCMD,
    // AALG, ALLG, APJLG, AVLG, COLG, DDLG, EMLG, GOPLG, ORCLG,
}

export default Images;