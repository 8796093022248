import { Box } from "@mui/material";
import React from "react";

const Map = () => {
  return (
    <>
      <Box
        sx={{
          minWidth: "100%",
          height: [350, 350, 400, 485, 360],
        }}
      >
        <iframe
          title="PH Engenharia e Projetos - Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1829.147859936304!2d-46.190257!3d-23.521864000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x98ca7cd2869572e7!2sPH%20Engenharia%20e%20Projetos!5e0!3m2!1spt-BR!2sbr!4v1647564843843!5m2!1spt-BR!2sbr"
          style={{ border: 0 }}
          width="100%"
          height="100%"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>
    </>
  );
};

export default Map;
