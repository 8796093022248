import React, { useState } from 'react';

import { Box, Card, CardContent, CardMedia, Grid, Typography, Skeleton } from '@mui/material';

const Text = (props) =>
{
    const { titulo, descricao } = props;

    return (
        <Grid item xs>
            <CardContent>
                <Typography variant="h5" component="div">
                    {titulo}
                </Typography>
                <Typography variant="body2">
                    {descricao}
                </Typography>
            </CardContent>
        </Grid>
    );
}

const Img = (props) =>
{
    const { titulo, foto, hideImgText } = props;

    const tituloUpper = titulo.toUpperCase();
    
    const [ isLoadingImg, setIsLoadingImg ] = useState(true);
    
    const handleLoad = () =>
    {
        setIsLoadingImg(!isLoadingImg);
    };

    return (
        <Grid item xs={12} sm={6} md={3} xl={2}>
            <Box 
                component="div"
                onLoad={handleLoad}
                sx={ { 
                    position: 'relative',
                } }
            >
                <Skeleton variant="rectangular" id="skeleton" width="100%" style={ isLoadingImg ? { } : { display: 'none' } } sx={ { paddingTop: '67%' } }/>
                
                <Box
                    component="div"
                    sx={ {
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                    } }
                />

                <CardMedia
                    component="img"
                    src={ foto.xs }
                    alt={titulo}
                    sx={ {
                        display: isLoadingImg ? 'none' : { },
                        height: '100%',
                        borderRight: [null, 1],
                        borderBottom: [1, null],
                        borderColor: 'lightgray !important',
                        // content: {
                        //     xs: `url(${foto.xs}) !important`,
                        //     sm: `url(${foto.sm}) !important`,
                        //     md: `url(${foto.md}) !important`,
                        //     lg: `url(${foto.lg}) !important`,
                        // } 
                    } }
                />

                <Box 
                    component="div"
                    sx={ { 
                        position: "absolute", 
                        color: "white", 
                        fontWeight: 600,
                        fontSize: 'x-large',
                        textShadow: '0 0 2px black',
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center"
                    } }
                >
                    {hideImgText ? '' : tituloUpper}
                </Box>
            </Box>
        </Grid>
    );
}

const CardImg = (props) => 
{
    const { titulo, descricao, foto, imgLeft } = props;

    let { hideImgText } = props;

    if (hideImgText === null || hideImgText === undefined )
    {
        hideImgText = false;
    }

    return (
        <Card 
            sx={ {
                minWidth: 275,
                mb: 4,
                border: 1,
                borderColor: 'lightgray',
                borderRadius: 1,
                boxShadow: '5px 5px 10px lightgray',
                backgroundColor: 'rgb(250, 250, 250)',
            } }
        >
            
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {imgLeft ?
                    <>
                        <Img titulo={titulo} foto={foto} hideImgText={hideImgText} />
                        <Text titulo={titulo} descricao={descricao} />
                    </>
                :
                    <>
                        <Text titulo={titulo} descricao={descricao} />
                        <Img titulo={titulo} foto={foto} hideImgText={hideImgText} />
                    </>
                }
            </Grid>
        </Card>
    );
}

export default CardImg;