import * as React from 'react';

import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

const CardText = (props ) => 
{
    const { titulo, descricao, icon } = props;

    return (
        <Card 
            sx={ {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                minWidth: 250,
                maxWidth: 350,
                mb: 1,
                mr: [0, 3],
                backgroundColor: "#C9935E",
                border: 1,
                borderColor: 'lightgray',
                borderRadius: 1,
                boxShadow: '5px 5px 10px lightgray'
            } }
        >
            
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                <Grid item xs={12}>
                    <CardContent >
                        <Box
                            component="div"
                            sx={ {
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: [0, 1]
                            } }

                        >
                            {icon}
                            <Typography 
                                variant="h5" 
                                component="div"
                                color="rgba(50, 50, 50, 1)"
                                sx={ { 
                                    display: 'inline-block',
                                    mx: 2,
                                    fontWeight: 600,   
                                } }
                            >
                                {titulo}
                            </Typography>
                        </Box>
                        <Typography variant="body2" color="rgba(50, 50, 50, 1)">
                            {descricao}
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    );
}

export default CardText;