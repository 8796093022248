import React from 'react';

import { Box } from '@mui/material';

export const DIRECTIONS =
{
    up: 'column-reverse',
    down: 'column',
    left: 'row-reverse',
    right: 'row'
};

const StyledUl = ( props ) => 
{
    const { direction, ...rest } = props;

    return (
        <Box
            component="ul"
            sx={ {
                display: 'flex',
                width: 'fit-content',
                listStyle: 'none',
                margin: '0',
                padding: '0',
                flexDirection: DIRECTIONS[direction],
                justifyContent: 'center',
                alignItems: 'center'
            } }
            {...rest}
        />
    );
};

export const Directions =
{
    Up: 'up',
    Down: 'down',
    Left: 'left',
    Right: 'right'
};

const FloatingMenu = (props) =>
{
    const { slideSpeed = 500, direction = Directions.down, isOpen = false, spacing = 8, children, ...rest } = props;

    const childrenWithProps = React.Children.map
    (
        children, (child, index) =>
        {
            return (
                React.cloneElement
                (  
                    child,
                    {
                        isOpen,
                        slideSpeed,
                        direction,
                        index,
                        spacing
                    }
                )
            );
        }
    );

    return (
        <StyledUl direction={direction} {...rest}>
            {childrenWithProps}
        </StyledUl>
    );
};

export default FloatingMenu;