import React, { useState } from 'react';

import { Box, Skeleton } from '@mui/material';

// import backgroundFotoXS from '../../media/Logo/webp/logo-background-xs.webp';
// import backgroundFotoMD from '../../media/Logo/webp/logo-background-md.webp';
import backgroundFotoXL from '../../media/Logo/webp/logo-background-xl.webp';
import mainLogo from '../../media/Logo/webp/logo-xl.webp';
import mainLogoReduced from '../../media/Logo/webp/logo-reduced-xs.webp';

const Home = () =>
{
    const [ isLoadingBGLogo, setIsLoadingBGLogo ] = useState(true);
    
    const handleLoad = () =>
    {
        setIsLoadingBGLogo(!isLoadingBGLogo);
    };

    return (
        <Box component="div" sx={ { pt: ["56px",  "70px"] } }>            
            <Skeleton variant="rectangular" id="skeleton" width="100%" style={ isLoadingBGLogo ? { } : { display: 'none' } } sx={ { paddingTop: '29.8%' } }/>
            
            <Box component="div" id="logo" style={ isLoadingBGLogo ? { display: 'none' }: { } } sx={ { position: "relative" } }>
                <Box
                    component="img"
                    alt="logo-background"
                    src={backgroundFotoXL}
                    onLoad={handleLoad}
                    sx={ {
                        py: 1,
                        width: '100%',
                        height: '100%',
                        // content: {
                        //     xs: `url(${backgroundFotoXS})`,
                        //     md: `url(${backgroundFotoMD})`,
                        //     xl: `url(${backgroundFotoXL})`,
                        // }
                    } }
                />
                <Box 
                    component="img"
                    alt="main-logo"
                    sx={ { 
                        position: "absolute", 
                        width: ['65%', '80%', '80%'],
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        textAlign: "center",
                        content: {
                            xs: `url(${mainLogoReduced})`,
                            sm: `url(${mainLogo})`,
                        }
                    } }
                />
            </Box>
        </Box>
    );
}

export default Home;