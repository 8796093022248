import React from 'react';

import { Typography } from '@mui/material';

const Titulo = (props) =>
{
    const { text, id } = props;

    return (
        <>
            <Typography
                id={id}
                variant={'h4'}
                sx={ {
                    my: 2,
                    pl: 2 ,
                    borderLeft: 5,
                    borderColor: "#C9935E",
                    fontWeight: 600,
                    fontSize: ['1.5rem', '1.75rem', '2rem', '2.25rem']
                } }
            >
            {text}
            </Typography>
        </>
    );
}

export default Titulo;