import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import WidgetFooter from './WidgetFooter';

const Footer = () =>
{
    return (
        <>
            <Box
                component='footer'
                sx={ {
                    mt: 'auto',
                } }
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={ {
                            mt: 2, 
                            borderTop: 1,
                            borderColor: 'lightgray'
                        } }
                    >
                        <WidgetFooter />
                    </Grid>
                    <Grid 
                        item
                        xs={12}
                        sx={ {
                            bgcolor: 'ghostwhite',
                            p: 2
                        } }
                    >
                        <Typography
                            variant="subtitle1"
                            align="center"
                            color="text.secondary"
                            component="p"
                        >
                            © 2022 PH Engenharia e Projetos. Todos os direitos reservados.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Footer;